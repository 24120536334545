.reflective-questions-container {
    width: 100%;
    height: 100%;
    /* overflow-y: auto; */
    padding: 0rem 0.5rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    /* gap: 0.65rem; */
}

.reflective-questions-header {
    background-color: #2a2c30;
    border-radius: 6px;
    width: 100%;
    align-items: center;
    height: 2.3125rem;
    display: flex;
    box-shadow: 0px 3px 6px #000000b0;
    justify-content: space-between;
    /* padding: 2% 0% 2% 0%; */
    box-sizing: border-box;
}

.reflective-questions-icon {
    /* padding: 0 0.5rem; */
    width: 2.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reflective-questions-header-content {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.25rem;
    color: #f2f2f2;
}

.reflective-close-icon-box {
    height: 100%;
    width: 2.875rem;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* padding: 0rem 0.5rem; */
    background-color: #000000b0;
}

.reflective-questions-close-icon {
    color: #86bc25;
    font-weight: 600;
    font-size: 1.2rem;
    opacity: 1;
    cursor: pointer;
}

.reflective-questions-content {
    width: 100%;
    height: calc(100% - 2.3125rem);
    overflow-y: auto;
    margin-top: 0.625rem;
}

.reflective-question-selection-area {
    background-color: white;
    border-radius: 7px;
    padding: 0.625rem;
    width: 100%;
    box-sizing: border-box;
}

.reflective-question-no {
    padding-bottom: 0.625rem;
    margin-bottom: 0.625rem;
    font-size: 0.75rem;
    border-bottom: 1px solid #E6E6E6;
}

.reflective-question-text {
    font-size: 0.875rem;
    color: #010101;
    /* font-style: italic; */
    padding-bottom: 0.5rem;
    /* padding-top: 0.625rem; */
}

.reflective-question-choice-items {
    width: max-content;
    max-width: 100%;
    margin-bottom: 1rem;
}

.reflective-category-desc-overlay {
    /* min-width: max-content; */
    max-width: 23rem;
}

.reflective-category-desc {
    color: white;
}

.reflective-question-choice {
    width: 100%;
    box-sizing: border-box;
    color: #056B39;
    background-color: white;
    font-size: 0.875rem;
    border-radius: 20px;
    margin-top: 0.5rem;
    padding: 0.4rem 1rem;
    border: 1px solid #86BC25;
    font-weight: 500;
    cursor: pointer;
}

.reflective-question-choice-selected {
    background-color: #d2e5b1;
}

.reflective-ins-text {
    font-style: italic;
    font-size: 0.875rem;
    padding-bottom: 0.625rem;
    color: #53565a;
    line-height: 21px;
}

.reflective-nav-buttons {
    display: flex;
    gap: 1.1875rem;
}

.reflective-nav-button {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    font-weight: 600;
    padding: 0 1rem;
    background-color: #86BC25;
    border-radius: 10px;
    height: 1.75rem;
    width: max-content;
    cursor: pointer;
    margin-top: 0.625rem;
}