.feedback-question-box-admin {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  color: #000;
  margin: 0.75rem 0rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}
.feedback-form-question-text {
  color: #000;
}
.admin-feedback-rating-no {
  display: flex;
  padding: 0.15rem 0rem;
  border-radius: 0.25rem;
  width: 2.5rem;
  justify-content: center;
  text-align: center;
}
.admin-feedback-rating-row {
  display: flex;
  gap: 1rem;
  box-sizing: border-box;
}
.show-text-area {
  height: 5rem;
  border-radius: 0.125rem;
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-1, #fff);
}
.add-new-button {
  border-radius: 0.125rem;
  border: 1px solid var(--Primary-green--, #92bb45);
  background: var(--Neutral-1, #fff);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  cursor: pointer;
  gap: 0.4rem;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  color: #92bb45;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  height: 2.5rem;
}
.add-question-options {
  width: max-content;
  border-radius: 0.25rem;
  background: #eee;
  cursor: pointer;
  /* drop-shadow/button-primary */
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);

  display: flex;
  color: var(--character-primary-inverse, #fff);
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  height: 2.5rem;
  align-items: center;
}
.plus-add-question-icon {
  border-radius: 0.125rem;
  border: 1px solid var(--Primary-green--, #92bb45);
  background: #fff;
  color: #92bb45;
  /* drop-shadow/button-primary */
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  padding: 0rem 0.6rem;
  line-height: 1.375rem;
  height: 100%;
}
.question-option-box {
  padding: 0.3rem 0.75rem;
  background-color: #fff;
  display: flex;
  align-items: center;

  gap: 0.5rem;
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
}
.multiple-option-box {
  background: #eee;
  height: 1.75rem;
  padding: 0.4rem 0.75rem;
  display: flex;
  width: max-content;
  gap: 0.5rem;
}
.save-button-bottom-section {
  display: flex;
  gap: 1rem;
  height: 2.5rem;
}
.question-edit-box {
  border-radius: 0.25rem;
  background: rgba(242, 242, 242, 0.39);
  border-top: 3px solid #2e6a3e;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  box-sizing: border-box;
  margin: 0.5rem 0rem;
}
.bottom-question-section {
  display: flex;
  width: 100%;
  justify-content: end;
  gap: 1rem;
  padding-right: 1rem;
  box-sizing: border-box;
}
.divider-rating {
  stroke-width: 1px;
  stroke: var(--Conditional-divider, rgba(0, 0, 0, 0.06));
  background-color: rgba(0, 0, 0, 0.06);
  /* width: 100%; */
  /* margin: 0% 1%; */
  border-radius: 0.25rem;
  min-height: 0.1rem;
}
.question-label-box {
  width: 100%;
  min-height: 2rem;
  height: max-content;
  /* border: none !important; */
  font-family: "Open Sans", sans-serif;
  border: 1px solid var(--Neutral-5, #d9d9d9) !important;

  box-sizing: border-box;
  padding-left: 0.5rem;
  outline: none !important;
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
}
.question-edit-no-box {
  display: flex;
  gap: 0.25rem;
  align-items: center;
}
.delete-question-pos {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  text-align: end;
  justify-content: flex-end;
}
.cursor-p {
  cursor: pointer;
}
.error-modal {
  display: flex;
  gap: 1rem;
  padding: 0rem 1rem;
  box-sizing: border-box;
  align-items: flex-start;
}
.info-icon {
  color: #007cb0;
  padding-top: 0.3rem;
}
.error-content {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
}
.setting-end {
  display: flex;
  width: 100%;
  justify-content: end;
}
.provide-header-text {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.title-input-box {
  /* width: 70%; */
  min-height: 2rem;
  height: max-content;
  /* border: none !important; */
  border: 1px solid var(--Neutral-5, #d9d9d9) !important;
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
  padding-left: 0.5rem;
  outline: none !important;
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
}
.title-text-limit {
  color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.title-section{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.25rem;
}
.imp-logo-pos{
  margin-bottom: 0.2rem;
}