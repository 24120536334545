.App {
  position: relative;
  display: inline-block;
  font-family: "Open Sans", sans-serif;
}

.App a {
  color: #007eb3;
  text-decoration: none;
}

.chat-bot-button{
  font-weight: 600;
  color: #F3F3F3;
  right: 0;
  bottom: 0;
  width: 11rem;
  height: 5rem;
  display: flex;
}

.ask-chat-tooltip{
  height: 2.5rem;
  width: 6rem;
}

.chat-bot-icon{
  width: 5rem;
  height: 5rem;
  cursor: pointer;
}

.chatbot-small-view.active{
  width: 5rem;
}
.css-t3ipsp-control{
  border-color: rgba(0, 0, 0, 0.06) !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06) !important;
}