.not-editable {
  background-color: rgba(217, 217, 217, 1);
}
.conven-text {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0em;
  text-align: left;
  color: #00000073;
}
