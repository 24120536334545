.w-30 {
  width: 30%;
}

.admin-email {
  color: #00a2ff;
}

.admin-role {
  padding: 1px 8px;
  border: 1px solid rgba(114, 46, 209, 1);
  border-radius: 2px;
  background: linear-gradient(0deg, #f9f0ff, #f9f0ff),
    linear-gradient(0deg, #722ed1, #722ed1);
}

.manage-role {
  padding: 1px 8px;
  color: rgba(212, 177, 6, 1);
  border: 1px solid rgba(212, 177, 6, 1);
  border-radius: 2px;
  background: linear-gradient(0deg, #feffe6, #feffe6),
    linear-gradient(0deg, #d4b106, #d4b106);
}
