.pdf-section-main-box {
  display: flex;
  flex-direction: column;
  width: 66.6%;
  height: calc(100% - 1rem);
  align-items: center;
  justify-content: center;
  padding: 0 0.625rem 0 0.625rem;
  margin-top: 0.625rem;
  box-sizing: border-box;
}

.pdf-action-bar {
  position: absolute;
  left: calc(100% - (2.6875rem + 12px + 8.5rem));
  top: 100%;
  display: flex;
  justify-content: space-between;
  width: calc(2.6875rem + 12px + 8.5rem);
  height: 1.75rem;
}

.pdf-download-button {
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  width: 2.6875rem;
  background-color: #86BC25;
  border-radius: 0px 0px 10px 10px;
}

.pdf-download-button img {
  width: 1.125rem;
  height: 1rem;
}

.pdf-slide-bar {
  width: 8.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  background-color: #444444;
  border-radius: 0px 0px 10px 10px;
}

.pdf-slide-bar .slide-number {
  font-size: 0.875rem;
  color: white;
}

.pdf-view-box {
  position: relative;
  height: calc(100% - 7.2rem);
  width: 100%;
  /* display: flex;
  justify-content: center; */
}

.pdf-summary-section {
  height: 7rem;
  /* margin-top: 0.5rem; */
  width: 100%;
  display: flex;
  /* background-color: white; */
  /* padding: 0.5rem; */
  box-sizing: border-box;
  border-radius: 10px;
}

.react-pdf__Page__canvas {
  height: 100% !important;
  width: 100% !important;
}

.pdf-document {
  height: 100% !important;
  width: 100% !important;
}

.pdf-page-wrapper {
  width: 100%;
  height: 0;
}

.pdf-page {
  /* height: 100% !important; */
  height: 0;
  width: 100% !important;
}

.navigate-pdf-icon {
  cursor: pointer;
  padding: 0 0.8rem;
}

.navigate-pdf-icon img {
  width: 0.5rem;
  height: 0.9rem;
}

.cursor-n {
  cursor: not-allowed;
}

.cursor-p {
  cursor: pointer;
}

.summary-box-filter-top {
  display: flex;
}

.summary-box-tip-arrow {
  width: 0;
  height: 0;
  border-left: 0.35rem solid transparent;
  border-bottom: 0.35rem solid transparent;
  border-top: 0.35rem solid #86BC25;
  border-right: 0.35rem solid #86BC25;
}

.summary-box-filter-heading {
  color: #000;
  background-color: #86BC25;
  border-radius: 0px 8px 8px 8px;
  padding: 0.2rem 1rem 0.2rem 0.5rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
}

.summary-box-filter-content {
  text-align: left;
  width: 98%;
  margin: 0.3rem 1rem;
  height: 4rem;
  overflow-y: auto;
  color: #4d4d4d;
  font-size: 0.875rem;
  font-style: normal;
  /* font-weight: 400; */
  line-height: 1.3125rem;
}

.spinner-summary {
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: max-content;
}

.pdf-summary-left-section {
  height: 100%;
  width: 5rem;
}

.pdf-summary-left-section img {
  width: 5rem;
  height: 5rem;
}

.pdf-summary-right-section {
  width: calc(100% - 6rem);
  margin-top: 0.5rem;
}

@media (max-width: 1250px) {
  .pdf-section-main-box {
      width: 63%;
  }
}