.feedback-question-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 7px;
  box-sizing: border-box;
  padding: 0.625rem;
  background-color: #ffffff;
  border-radius: 7px;
  gap: 0.5rem;
}

.question-no-box {
  padding-bottom: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1.5px solid #53565a2b;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #1F1F1F;
}

.question-box {
  display: flex;
  flex-direction: column;
  gap: 0.375rem;

  font-size: 0.875rem;
  line-height: 21px;
  /* color: #010101; */
}

.question-box-info{
  font-style: italic;
  color: #595959;
}

.rating-box-container {
  display: flex;
  flex-wrap: wrap;
  gap: 7px 0;
  /* width: 104%;
  transform: translateX(-12px); */
}

.rating-box {
  display: flex;
  flex: 1;
  width: 100%;
  height: 2rem;
  justify-content: space-around;
  /* padding-top: 0.5rem; */
  box-sizing: border-box;
}

.rating-no-box {
  padding: 0.75rem 0.65rem;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  width: 2.05rem;
}

.rating-index {
  font-size: 0.875rem;
  line-height: 1.188rem;
  font-weight: bold;
  opacity: 1;
}

.comments-box {
  height: 5rem;
  width: 98%;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #010101;
  border: 1px solid #d1d1d1;
  box-shadow: 2px 2px 0px #00000029;
  outline: none;
}

.comments-box:focus-visible {
  border: 1px solid #d1d1d1;
  outline: none;
}

.comments-box:disabled {
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-4, #f0f0f0);
  cursor: not-allowed;
  color: black;
}
.r-color{
  color: #53565a;
}
.question-no-box .center-align{
  display: flex;
  align-items: center;
  font-weight: 600;
}