.filter-option {
    position: relative;
}

.filter-header {
    border: 1px solid #c2c2c2;
    background-color: #ffffff;
    height: 1.925rem;
    box-sizing: border-box;
    color: #056B39;
    height: 1.3rem;
    padding: 0.25rem 0.5rem;
    font-size: 0.688rem;
    cursor: pointer;
    gap: 0.1rem;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.filter-options {
    position: absolute;
    bottom: 3rem;
    display: flex;
    flex-direction: column;
    width: max-content;
    background-color: white;
    box-shadow: 0px 5px 6px #00000029;
    border-radius: 10px;
    padding: 0.5rem;
    font-size: 0.7rem;
}