.pdf-bot-main-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #86BC25;
    height: 100%;
    box-sizing: border-box;
    box-shadow: 5px 5px 10px #00000029;
    border-radius: 10px;
}

.pdf-bot-content-box {
    height: calc(100% - 3rem - 2px);
    display: flex;
    /* align-items: center; */
    width: calc(100% - 4px);
    background-color: #F6F6F6;
    border-radius: 10px;
    box-shadow: inset 0px 3px 6px #00000029;
}

.pdf-bot-content-box .vertical-separator {
    height: calc(100% - 3rem);
    width: 1px;
    background-color: #D5D5D5;
}

.pdf-chat-section-box {
    display: flex;
    flex-direction: column;
    width: 33.3%;
    height: 100%;
    /* padding: 1rem; */
    box-sizing: border-box;
    justify-content: space-between;
}

.suggestive-question-section {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-bottom: 1px solid #D5D5D5;
    /* height: 8rem; */
    border-radius: 10px 10px 0 0;
}

.user-question-no-filter {
    padding-top: 0.5rem;
    padding-left: 1rem;
    display: flex;
}

.suggestive-icon-filter {
    background-color: #219be4;
    width: 0.35rem;
    border-radius: 50%;
    height: 0.35rem;
    margin-right: 2%;
}

.suggestive-question-no-container {
    display: flex;
    width: max-content;
    gap: 0.5rem;
    font-weight: 600;
    font-size: 0.875rem;
}

.suggestive-text-filter {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
}

.suggestive-question-number-filter {
    font-size: 0.75rem;
}


.info-icon-filter {
    margin-left: 2%;
    width: 0.75rem;
    height: 0.75rem;
}

.question-arrow-filter {
    /* width: 10%; */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    :disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
}

.question-arrow-filter img {
    width: 0.5rem;
    height: 0.9rem;
}

.user-question-text-filter {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0.5rem 1rem 1rem 1rem;
}

.selected-question-filter {
    background: rgba(66, 153, 247, 0.1) !important;
}

.question-box-tooltip-filter {
    font-size: 0.875rem;
    color: white;
}

/* .user-question-check-filter {
    width: 80%;
} */

.user-question-filter {
    color: #4d4d4d;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3125rem;
    /* height: 3.7rem; */
    border: 1px solid #86BC25;
    color: #056B39;
    border-radius: 25px;
    padding: 0.4rem 1rem;
    display: flex;
    align-items: center;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    margin-top: 0.2rem;
}

.user-question-filter:hover {
    background-color: #D2E5B1;
}

.no-question-available-filter {
    display: flex;
    width: 96%;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    padding: 3% 2% 0.5% 2%;
    margin-bottom: 0.35rem;
}

.spinner-questions {
    padding: 2%;
}

.responses-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
    /* height: 100%; */
    overflow-y: auto;
}

.supertutor-feedback-message {
    width: 66.6%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0.6rem;
}

@media (max-width: 1250px) {
    .pdf-chat-section-box {
        width: 37%;
    }

    .supertutor-feedback-message {
        width: 63%;
    }
}