.filter-usage-header {
  display: flex;
  justify-content: space-between;
  border-radius: 0.1875rem 0.1875rem 0rem 0rem;
  background: rgba(164, 185, 170, 0.05);
  padding: 0.75rem 1.25rem;
  height: 4rem;
  box-sizing: border-box;
  align-items: center;
}
.filter-usage-heading {
  color: var(--neutral-12, #141414);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
}
.select-filter-box {
  background: var(--neutral-1, #fff);

  /* drop-shadow/button-secondary */
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  width: 10rem;
}
.filter-graph-box {
  width: 100%;
  height: calc(100% - 3.9rem);
  display: flex;
  padding: 0rem;
  justify-content: space-between;
  box-sizing: border-box;
}
.pie-chart {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
}
.graph-legend-box {
  width: 50%;
  height: 100%;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  justify-content: center;
}
.graph-legend-box::-webkit-scrollbar {
  width: 0px;
}
.legend-box {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  /* gap: 4%; */
  width: 100%;
  max-height: 100%;
  height: auto;
}
.option-detail-box {
  width: 46%;
  display: flex;
}
.icon-option-name-box {
  width: 80%;
  gap: 0.5rem;
  display: flex;
  align-items: center;
}
.option-name {
  color: var(--neutral-30, #d4d4d4);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 166.667% */
  width: 100%;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.option-value-box {
  white-space: nowrap;
  color: var(--neutral-7, #8c8c8c);
  text-align: right;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
}
.color-icon {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  /* padding-top: 0.5rem;
  box-sizing: border-box; */
}
.filter-graph-filter-heading {
  padding-left: 1.25rem;
  box-sizing: border-box;
  color: var(--neutral-9, #434343);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  width: 100%;
  height: 2rem;
  line-height: 1.75rem; /* 140% */
}
.pie-chart-graph-box {
  width: 80%;
  padding-left: 1rem;
  height: calc(100% - 2rem);
  flex: 1;
}
.card-header-box{
  display: flex;
  gap: 1rem;
  align-items: center;
}
.card-tooltip-container{
  color: black;
  font-size: 0.75rem;
  line-height: 1.3125rem;
  font-weight: 400;
  padding: 0.25rem;
}
.card-container-heading{
  font-weight: 600;
}
.card-info-icon-sizing{
  width: 1.3rem;
}