.contents-table-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  margin-top: 0.75rem;
}

.table-header {
  display: flex;
  background-color: #f5f5f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.header-options {
  color: black;
  /* padding-left: 0.5rem; */
  padding: 0.8rem 0.75rem;
  box-sizing: border-box;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
}
.content-options {
  display: flex;
  gap: 0.75rem;
  color: black;
  /* padding-left: 0.5rem; */
  padding: 0.6rem 0rem;
  padding-left: 0.5rem;
  box-sizing: border-box;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
}
.w-26 {
  width: 26%;
}
.w-40 {
  width: 44%;
}
.w-20 {
  width: 10%;
}
.w-10 {
  width: 10%;
}
.w-15 {
  width: 10%;
}
.table-content {
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
}
.icon-sizing {
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}
.link-name {
  color: #007eb3 !important;
  word-wrap: break-word;
}
.pad-left{
  padding-left: 1.4rem !important;
}
.p-left-9{
  padding-left: 0.9rem !important;
}