.radio-label input[type="radio"] {
    display: none;
    /* Hide the default radio button */
}

.radio-label {
    position: relative;
    padding-left: 25px;
    margin-right: 15px;
    font-size: 0.875rem;
    cursor: pointer;
}

.radio-label::before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 2px solid rgba(146, 187, 69, 1);
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 2px;
    box-sizing: border-box;
}

.radio-label::after {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgba(146, 187, 69, 1);
    position: absolute;
    left: 4px;
    top: 6px;
    opacity: 0;
}

.radio-label.selected::after {
    opacity: 1;
}

.radio-label input[type="radio"]:checked+::after {
    opacity: 1;
}