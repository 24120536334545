.filter-row {
  background-color: #dfdfdf;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0rem 0.25rem;
  height: 2.15rem;
  overflow-x: auto;
}

.filter-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 3rem;
}

.filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: none;
  border-right: 1px solid #D5D5D5;
  border-radius: 0 0 0 10px;
  cursor: pointer;
  background-color: white;
}

.filter-button img {
  width: 1rem;
  height: 1.1rem;
}

.filter-button-disabled,
.filter-button-disabled img {
  opacity: 0.5;
  cursor: not-allowed;
}

.filter-box-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 8px;
  bottom: 3.5rem;
  padding: 14px;
  background-color: white;
  box-shadow: 0px 4px 4px 0px rgba(110, 113, 100, 0.1);
  /* box-shadow: 0px 3px 5px #0000001C; */
  border: 1px solid #E2E2E2;
}

.filter-option-nav {
  display: flex;
  justify-content: space-between;
  height: 1rem;
  margin-bottom: 4px;
}

.filter-option-left-nav {
  width: 50%;
  display: flex;
  justify-content: start;
}

.filter-option-right-nav {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.filter-icon img {
  width: 2rem;
  height: 2rem;
}

.button-section {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  line-height: 1.3125rem;
  color: black;
  font-weight: 500;
}

.buttons-bar {
  display: flex;
  gap: 0.7rem;
}

.filter-placeholder {
  height: 2rem;
  width: 100%;
  position: relative;
}

.filter-container {
  width: 100%;
  height: 30rem;
  position: absolute;
  bottom: -0.3rem;
  overflow-x: auto;
}

.filter-bar {
  height: 2rem;
  position: absolute;
  bottom: 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
  gap: 0.625rem;

}

.filter-container::-webkit-scrollbar {
  width: 1px;
  /* Set the width as needed */
  height: 3px;
}

.filter-container::-webkit-scrollbar-thumb {
  background-color: transparent;
  /* border-radius: 10px; Make the thumb transparent */
}

.filter-container::-webkit-scrollbar-track {
  background-color: transparent;
  /* Make the track transparent */
}

.all-trainings-button {
  padding: 0.25rem 0.8rem;
  background-color: #92BB45;
  color: white;
  cursor: pointer;
  text-align: center;
  border-radius: 2px;
}

.reset-button {
  cursor: pointer;
  padding: 0.25rem 0.8rem;
  background-color: white;
  color: #056B39;
  text-align: center;
  border: 1px solid #92BB45;
  border-radius: 2px;
}

.filters-heading {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.3125rem;
}

.filter-row::-webkit-scrollbar {
  width: 1px;
  /* Set the width as needed */
  height: 3px;
}

.filter-row::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  /* border-radius: 10px; Make the thumb transparent */
}

.filter-row::-webkit-scrollbar-track {
  background-color: transparent;
  /* Make the track transparent */
}

.filter-all-option-box {
  border: 1px solid #c2c2c2;
  background-color: #ffffff;
  color: #056B39;
  height: 1.3rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  cursor: pointer;
  gap: 0.1rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.apply-clear-container {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.3rem 0rem;
}

.clear-all-option {
  color: #dd2c00;
  cursor: pointer;
}

.apply-option {
  color: rgba(38, 137, 13, 1);
  cursor: pointer;
}

.singlefilter-options-box {
  position: absolute;
  bottom: 0rem;
  max-width: 80%;
  margin-bottom: 6.3rem !important;
  background-color: white;
  box-shadow: 0px 5px 6px #00000029;
  border-radius: 10px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.options-box {
  display: flex;
  align-items: start;
  font-size: 0.7rem;
}

.input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.input[type='radio']:checked:after {
  background-color: #ffa500;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.selected-number {
  position: absolute;
  top: 2px;
  left: 52%;
  color: #ffffff;
  text-align: center;
  background-color: #86BC25;
  font-size: 0.75rem;
  font-weight: 600;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.2rem;
  height: 1.2rem;
}

.clear-box {
  border-radius: 50% 0% 0% 50%;
  border: 1px solid #87bd27;
  background-color: #ffffff;
  color: #056B39;
  height: 1.3rem;
  padding: 0rem 0.8rem;
  font-size: 0.75rem;
  cursor: pointer;
  text-align: center;
}

.filter-nav-box {
  display: flex;
  gap: 0.25rem;
  width: calc(100% - 2rem);
}