.training-table {
    background-color: white;
    padding: 0.5rem;
    border-radius: 7px;
}

.table-container::-webkit-scrollbar {
    width: 6px;
    /* Set the width as needed */
}

.table-container::-webkit-scrollbar-thumb {
    background-color: #dfdfdf;
    border-radius: 7px;
    /* Make the thumb transparent */
}

.table-container::-webkit-scrollbar-track {
    background-color: transparent;
    /* Make the track transparent */
}

.table-container table {
    border-collapse: collapse;
    width: 100%;
}

.table-container table tr:not(:last-child) {
    border-bottom: 2px solid #e5e5e5;
}

.weight {
    font-weight: 600;
}

.table-content-box {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    width: fit-content;
    padding: 0.25rem 0.5rem;
    background-color: #FFF;
    border-radius: 7px;
}

.training-table .table {
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.3125rem;
}

.training-table .table th {
    text-align: start;
    font-weight: 400;
    font-size: 0.875rem;
}

.training-table .table td {
    padding: 0.5rem 0rem;
}

.prof-reg-box {
    display: flex;

}

.fw-600 {
    font-weight: 600;
}

.name {
    max-width: 0rem;
    color: black;
    cursor: pointer;
}

.cell-width-22 {
    width: 20%;
}

.cell-width-10 {
    width: 10%;
}

.cell-width-68 {
    width: 66%;
}

.cell-width-65 {
    width: 65%;
}

.cell-2 {
    width: 5%;
}

.link {
    color: #056B39;
    text-decoration: none;
}

/* .source-link-color {
    display: inline-block;
    color: #00A2FF;
    cursor: pointer;
    font-style: italic;
} */

.training-table-tooltip.ant-tooltip {
    box-shadow: 0px 5px 6px #00000029;
    border: 1px solid #E8E8E8;
    border-radius: 10px;
    background: #FFFFFF;
    max-width: 25rem;
    min-width: 25rem;
    left: 1rem !important;
}

.tooltip-box-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

}

.sub-box-tootip {
    display: flex;
    flex-direction: column;
    text-align: start;
    color: white;
    font-size: 0.75rem;

}

.sub-box-tootip-1 {
    display: flex;
    flex-direction: column;
    text-align: start;
    color: white;
    font-size: 0.75rem;
    width: 50%;
}

.sub-heading-tooltip {
    font-weight: bold;
}

.t-box {
    width: 100%;
}

.training-table-tooltip.ant-tooltip.css-qgg3xn.ant-tooltip-placement-bottom {
    max-width: 25rem;
    min-width: 25rem;
}

.training-table-tooltip.ant-tooltip.css-qgg3xn.ant-tooltip-placement-top {
    max-width: 25rem;
    min-width: 25rem;
}

.view-all-button {
    color: rgba(147, 187, 68, 1);
    font-size: 0.875rem;
    font-weight: 500;
}

/* New design changes */

.training-list-container {
    background-color: white;
    /* width: 22.3rem; */
    width: max-content;
    max-width: 82%;
    box-sizing: border-box;
    padding: 12px;
    box-shadow: 0px 3px 5px #0000001C;
    border: 1px solid #E2E2E2;
    border-radius: 6px;
}

.training-list-heading {
    font-size: 0.875rem;
    /* padding-bottom: 0.625rem; */
    line-height: 21px;
    /* border-bottom: 1px solid rgba(28, 37, 10, 0.6); */
}

.training-list {
    margin-top: 0.625rem;
}

.training-list-items {
    border-top: 1px solid rgba(28, 37, 10, 0.3);
    padding: 0.625rem 0;
}

.training-list-items:first-child {
    border-top: 1px solid rgba(28, 37, 10, 0.6);
}

.training-item-link {
    font-size: 0.875rem;
    font-weight: 600;
    color: #00A2FF;
    text-decoration: underline !important;
}

.training-item-link-info img {
    padding-left: 0.5rem;
    width: 1rem;
    height: 1rem;
    transform: translateY(3px);
}

.training-item-other-info {
    display: flex;
    gap: 0.5rem;
    font-size: 12px;
    margin-top: 0.5rem;
}

.training-items-vertical-separator {
    width: 1px;
    background-color: rgba(28, 37, 10, 0.4);
}

.view-all-tooltip {
    position: relative;
    display: inline-block;
}

.view-all-tooltip-content {
    position: absolute;
    bottom: 1.5rem;
    width: 20rem;
    background-color: white;
    color: #333;
    font-size: 0.875rem;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.25);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.view-all-tooltip:hover .view-all-tooltip-content {
    visibility: visible;
    opacity: 1;
}

.retain-context {
    margin-top: 0.625rem;
    cursor: pointer;
    width: max-content;
    max-width: 94%;
    font-size: 0.875rem;
    border-radius: 20px;
    padding: 0.4rem 0.5rem;
    color: #056B39;
    border: 1px solid rgba(147, 187, 68, 1);
}

.retain-context-selected {
    /* color: white; */
    background-color: #D2E5B1;
    opacity: 1 !important;
}

.mod-search-disabled {
    pointer-events: none;
    cursor: default;
}

.retain-context.mod-search-disabled {
    opacity: 0.5;
}