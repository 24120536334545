.line-graph-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 3.9rem);
  text-align: center;
  align-items: center;
  padding: 0rem;
  box-sizing: border-box;
  /* border: 1px solid red; */
  justify-content: center;
}
.line-graph-sizing {
  /* width: 50%; */
  width: 100%;
  padding: 0rem 1rem;
  box-sizing: border-box;
  height: 100% !important;
  text-align: start;
}
.select-filter-box-use-case {
  width: 15rem;
  outline: none;
}
.mt--2 {
  margin-top: -2rem;
}
