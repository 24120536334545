.training-heading-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 0.5rem;
  box-sizing: border-box;
  align-items: center;
}
.create-button {
  padding: 0.25rem 0.65rem;
  cursor: pointer;
  background-color: #92bb45;
  color: white;
  height: 2rem;
  align-items: center;
  text-align: center;
  border: 1px solid #92bb45;
  display: flex;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  border-radius: 0.125rem;
}
.training-content {
  color: #141414;
  /* H4/medium */
  /* font-family: Open Sans; */
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem;
}
.pagination-box {
  width: 100%;
  margin-top: 0.5rem;
  box-sizing: border-box;
  text-align: end;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: end;
}
.cursor-n {
  cursor: not-allowed !important;
}
.centered-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding:0rem 0.5rem;
  min-height: 2rem;
  border-radius: 0.125rem;
  border: 1px solid var(--Neutral-5, #d9d9d9);
  background: var(--Neutral-1, #fff);
}
.go-to-text {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  text-align: right;

  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 157.143% */
}
.page-input-box{
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-left: 0.5rem;
  font-family: "Open Sans", sans-serif;
}
.pagination-input-box{
  outline: none;
  border-radius: 0.125rem;
  border: 1px solid var(--Neutral-5, #d9d9d9);
  background: var(--Neutral-1, #fff);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  height: 2rem;
  width: 2rem;
  text-align: center;
  font-family: "Open Sans", sans-serif;
}
.error-color{
  color: red;
  border-color: red;
}