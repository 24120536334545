.no-pdf-content {
    display: flex;
    width: calc(100% - 3rem);
}

.no-pdf-content-left {
    width: 17.8rem;
}

.no-pdf-content-left img {
    width: 17.8rem;
    height: 17.8rem;
}

.no-pdf-content-right {
    display: flex;
    height: max-content;
    align-items: flex-end;
    width: calc(100% - 17.8rem);
    margin-top: 2rem;
}

.supertutor-tip-arrow {
    width: 0;
    height: 0;
    border-left: 0.7rem solid transparent;
    border-bottom: 0.7rem solid #86BC25;
    border-top: 0.7rem solid transparent;
    border-right: 0.7rem solid #86BC25;
}

.supertutor-tip-content {
    /* width: max-content; */
    width: 100%;
    background-color: #86BC25;
    padding: 0.3rem;
    border-radius: 8px 8px 8px 0px;
    display: flex;
    align-items: center;
    font-size: 1.125rem;
}

.supertutor-tip-content img{
    width: 3.75rem;
    height: 4.2rem;
    padding: 0.4rem 0 0.2rem 0;
}

.tip-text-separator{
    width: 1px;
    height: 4rem;
    background-color: #AAE149;
}

.supertutor-tip-text{
    padding: 0 1rem;
}