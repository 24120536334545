.pdf-chat-bot-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* gap: 0.3rem; */
    height: 3rem;
    width: 100%;
    padding: 0rem 1rem;
    box-sizing: border-box;
}

.pdf-chat-bot-tab-container {
    width: max-content;
    height: 100%;
    display: flex;
    align-items: flex-end;
}

.pdf-chat-bot-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* width: 11.25rem; */
    height: 2.25rem;
    padding: 0rem 1rem;
    text-align: center;
    font-size: 0.875rem;
    font-weight: bold;
}

.pdf-chat-bot-selected-tab {
    border-radius: 6px 6px 0px 0px;
    background-color: white;
    color: black;
}

.pdf-chat-bot-nav-container {
    display: flex;
    align-items: center;
    gap: 0.5625rem;
    justify-content: space-between;
}

.chat-bot-links-right-exp {
    margin-left: 1rem;
}

.header-tooltips {
    color: white;
}

.header-tooltips-width.ant-tooltip {
    max-width: max-content;
    min-width: max-content;
}

.header-tooltips-fixed-width-1.ant-tooltip {
    max-width: 20rem;
    min-width: 20rem;
}

.header-tooltips-fixed-width-2.ant-tooltip {
    max-width: 23rem;
    min-width: 23rem;
}

.minimize-button-tooltip {
    max-width: 23rem;
}

.tour-nav-button {
    all: unset;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    width: 2rem;
    background-color: #86BC25;
    box-shadow: 0px 3px 6px #00000036;
    border-radius: 4px;
    font-size: 0.75rem;
    cursor: pointer;
}

.tour-nav-img {
    height: 1.25rem;
    width: 1.25rem;
}

.tour-nav-button img {
    height: 100%;
    width: 100%;
}