.contents-table-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  margin-top: 0.75rem;
}

.table-header {
  display: flex;
  background-color: #f5f5f5;
  gap: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.header-options {
  color: black;
  padding: 0.8rem 0rem;
  padding-left: 0.5rem;
  box-sizing: border-box;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
}
.content-options {
  display: flex;
  gap: 1.5rem;
  color: black;
  padding: 0.6rem 0rem;
  padding-left: 0.5rem;
  box-sizing: border-box;
  font-size: 0.875rem;
  font-style: normal;
  word-break: break-word;
  font-weight: 400;
  line-height: 1.375rem;
}
.c-30 {
  width: 30%;
}
.c-50 {
  width: 55%;
}
.c-15 {
  width: 15%;
}
.c-10 {
  width: 10%;
}

.table-content {
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
}
.icon-sizing {
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}
.link-name {
  color: #007eb3 !important;
  word-break: break-word;
}
