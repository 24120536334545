.custom-checkbox-2 {
    width: calc(100% - 1.5rem);
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    margin: 0.6rem 0rem;
}

.custom-checkbox-2 input {
    display: none;
}

.custom-checkbox-2 label {
    cursor: pointer;
}

.custom-checkbox-2 label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 17px;
    height: 17px;
    border: 1px solid gray;
    background-color: #ffffff;
    cursor: pointer;
}

.custom-checkbox-2 input:checked+label::before {
    content: '\2713';
    font-size: 18px;
    color: #046A38;
    text-align: center;
    line-height: 20px;
}