.bulk-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  gap: 1rem;
  margin-top: 0.75rem;
}
.template-box {
  display: flex;
  gap: 0.5rem;
}
.template-content {
  overflow: hidden;
  color: var(--Hyper-link, #007cb0);
  text-overflow: ellipsis;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem; /* 157.143% */
  border-bottom: 1px solid #007cb0;
  padding: 0rem 0.25rem;
}
.upload-file-box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}
.upload-file-box-bulk {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 10.5rem;
  width: 100%;
  margin-bottom: 1.25rem;
}

.upload-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1.5rem 0rem;
  box-sizing: border-box;
  border-radius: 0.125rem;
  gap: 0.25rem;
  cursor: pointer;
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-2, #f2f2f2);
}
.upload-1 {
  width: 100%;
}
.icon-color-inbox {
  color: #2e6a3e !important;
  width: 2rem;
  height: 2rem;
}
.ant-upload-drag {
  border: none !important;
}
.ant-upload {
  width: 100% !important;
}
.file-limit-content {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  color: rgba(0, 0, 0, 0.45);
}
.download-button {
  cursor: pointer;
  color: #2e6a3e !important;
}
.submit-button:disabled {
  cursor: not-allowed !important;
}
.guidelines-box {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  padding: 0.875rem;
  box-sizing: border-box;
  padding-right: 1.5rem;
  border-radius: 0.25rem;
  width: max-content;
  background: #f1f8f3;
}
.bulk-upload-info-statement {
  display: flex;
  width: max-content;
  gap: 0.5rem;
  align-items: center;
  color: var(--primary-10, #001017);

  /* Body/medium */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem; /* 157.143% */
}
.ins-content-box {
  color: var(--neutral-8, #595959);
  padding-left: 0.5rem;
  /* Body/regular */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 157.143% */
}
