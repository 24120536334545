.custom-tooltip {
    position: relative;
    display: flex;
    align-items: center;
    /* height: 100%; */
    /* width: 100%; */
}

.custom-tooltip-content {
    position: absolute;
    z-index: 1500;
    /* bottom: 100%; */
    width: max-content;
    background-color: #444444;
    color: white;
    font-size: 0.875rem;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.25);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.custom-tooltip:hover .custom-tooltip-content {
    visibility: visible;
    opacity: 1;
}