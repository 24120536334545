.trainings-container {
    /* padding: 0.75rem 1rem 0.75rem 1rem; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.75rem;
}

.trainging-heading {
    font-size: 0.75rem;
    color: #000000;
    padding-top: 1%;
    padding-left: 2%;

}

.request-box {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    width: fit-content;
    padding: 0.25rem 0.5rem;
    background-color: #FFF;
    border-radius: 7px;
}

.recommended-trainings-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.response-message {
    font-size: 0.875rem;
    color: #000000;
    padding-left: 3%;
}

.table-content-box {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    width: fit-content;
    padding: 0.25rem 0.5rem;
    background-color: #FFF;
    border-radius: 7px;
}

.see-more-pos {
    width: max-content;
    margin-top: 0.2rem;
}

.training-message-wrapper{
    display: flex;
}

.training-message{
    background-color: white;
    box-sizing: border-box;
    width: max-content;
    /* max-width: 22.3rem; */
    max-width: 82%;
    padding: 12px;
    box-shadow: 0px 3px 5px #0000001C;
    border: 1px solid #E2E2E2;
    border-radius: 6px;
    font-size: 0.875rem;
}

.training-message ol{
    padding-left: 1rem;
    margin: 0;
    line-height: 21px;
}

.entered-filter-text{
    text-decoration: underline;
}

.selected-filters-hover-text{
    color: white;
    display: flex;
    gap: 0.5rem;
}

.selected-filters-hover-key{
    font-weight: 600;
    text-wrap: nowrap;
}