.collapse-add-category-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--Neutral-Neutral-0, #fff); /* Set background color */
  box-shadow: 0px 0.929px 1.857px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  padding: 1rem 0.75rem;
  box-sizing: border-box;
  gap: 1rem;
}
.add-ctaegory-content-box {
  width: 100%;
  /* padding: 1rem 0.75rem; */
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}
.enter-category-section {
  width: 48%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.enter-category-desc-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  /* height: 5rem; */
}
.category-name-input {
  border-radius: 0.125rem;
  border: 1px solid var(--Neutral-5, #d9d9d9);
  background: var(--Neutral-1, #fff);
  padding: 0.3125rem 0.75rem;
  height: 2rem;
  padding-left: 0.5rem;
  box-sizing: border-box;
  width: 100%;
}
.category-name-input:focus {
  outline: none !important;
}
.category-desc-input {
  border-radius: 0.125rem;
  border: 1px solid var(--Neutral-5, #d9d9d9);
  background: var(--Neutral-1, #fff);
  padding: 0.3125rem 0.75rem;
  height: 5rem;
  padding-left: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  font-family: 'Open Sans';
}
.category-desc-input:focus {
  outline: none !important;
}
.category-search-bar {
  border-radius: 0.125rem;
  border: 1px solid var(--Neutral-5, #d9d9d9);
  background: var(--Neutral-1, #fff);
  height: 2rem;
  box-sizing: border-box;
  width: 100%;
}
.section-text {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  display: flex;
  gap: 0.25rem;
  font-size: 0.875rem;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
}
.ant-input-group .ant-input-group-addon {
  border-radius: 0.125rem !important;
}
.ant-input-group-wrapper-outlined .ant-input-group-addon {
  border: none !important;
}
.ant-input-outlined {
  border: none !important;
  border-left: 1px solid #d9d9d9;
}
.category-section-buttons {
  display: flex;
  width: 100%;
  text-align: end;
  align-items: end;
  flex-direction: column;
  gap: 1rem;
}
.close-save {
  display: flex;
  gap: 1.5rem;
}
.collapse-add-question-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0.375rem;
  border: 1px solid var(--Neutral-5, #d9d9d9);
  padding: 1rem 0.75rem;
  box-sizing: border-box;
  gap: 1rem;
}
.enter-question-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
}
.add-question-content-box {
  width: 100%;
  gap: 3.375rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.questions-option-main-box {
  background: #f6faf1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0.75rem;
  box-sizing: border-box;
  gap: 1rem;
}
.options-divider{
  display: flex;
  width: 100%;
  gap: 0.5rem;
  align-items: center;
  /* padding: 0rem 1rem; */
  box-sizing: border-box;
}
.options-text {
  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
  font-size: 1rem;
  padding: 0rem 0.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
}
.options-divider-shade{
  flex: 1 1;
  display: flex;
  height: 0.1rem;
  background-color: rgba(0,0,0,.06);
}