.gradient-graph-box {
  /* width:-webkit-fill-available; */
  /* width: 22.5%;
  height: 100%; */
  width: 46%;
  height: 19rem;
  /* padding: 0rem 0.2rem; */
  box-sizing: border-box;
}
.graph-heading-content-box {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  box-sizing: border-box;
  padding-left: 1rem;
}
.graph-count-box {
  display: flex;
}
.graph-count {
  color: var(--Neutral-12, #141414);
  font-size: 0.80675rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.21019rem; /* 150% */
}
.graph-count-text {
  color: var(--Neutral-8, #595959);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.21019rem;
}
.graph-heading {
  color: var(--Neutral-9, #434343);
  font-size: 0.70594rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.10931rem; /* 157.143% */
}
.area-chart{
    height: calc(100% - 1.8rem);
}
.w-h-100{
  width: 100%;
  height: calc(100% - 4rem);
}