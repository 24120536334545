.w-100{
    width: 100%;
}
.graph-count-box-between{
    display: flex;
    gap: 1rem;
}
.graph-count-less-bold{
    font-size: 0.75rem;
    font-weight: 400;
}