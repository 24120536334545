.custom-checkbox {
  width: max-content;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  margin: 0.1rem 0rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}

.custom-checkbox input {
  display: none;
}
.heading-width {
  width: max-content !important;
  font-size: 1rem;
  font-weight: 600;
}
.custom-checkbox label {
  cursor: pointer;
}

.custom-checkbox label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 17px;
  height: 17px;
  border: 1px solid gray;
  background-color: #ffffff;
  cursor: pointer;
}

.custom-checkbox input:checked + label::before {
  content: "\2713";
  font-size: 18px;
  color: #046a38;
  text-align: center;
  line-height: 20px;
}
.heading-width input:checked + label::before {
  content: "\25A0" !important;
  font-size: 14px !important;
  color: #046a38;
  text-align: center;
  line-height: 16px !important;
}
