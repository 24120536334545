.custom-checkbox {
    width: 100%;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    margin: 0.1rem 0rem;
}

.custom-checkbox input {
    display: none;
}

.custom-checkbox label {
    cursor: pointer;
}

.custom-checkbox label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 17px;
    height: 17px;
    border: 1px solid gray;
    background-color: #ffffff;
    cursor: pointer;
}

.custom-checkbox input:checked+label::before {
    content: '\2713';
    font-size: 18px;
    color: #046A38;
    text-align: center;
    line-height: 20px;
}