.questions-gen-ai-container-expanded {
    display: flex;
    flex-direction: column;
    /* margin-top: 0.625rem; */
    padding: 0rem 0.625rem 0.1rem 0.625rem;
    /* height: calc(100% - 1.2rem); */
    /* overflow-y: auto; */
}

.question-instructions {
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    text-align: start;
}

.gen-ai-questions {
    padding: 1% 2% 1% 3%;
    font-size: 0.7rem;
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    background-color: white;
    border-radius: 7px;
}

.domain-option {
    width: max-content;
    padding: 1% 2% 1% 0%;
    color: #056B39;
    font-size: 0.875rem;
    font-weight: 500;
    text-align: start;
    cursor: pointer;
    font-style: normal;
}

.domain-option-selected {
    background-color: #EDF5DE;
}

.training-option-selected {
    background-color: aquamarine;
}

.chatbot-response {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.aligning-message {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    /* padding-right: 1rem; */
}

.default-questions {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.loading-chat {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}