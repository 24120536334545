.chat-bot-container {
    display: flex;
}

.chat-bot-content-box {
    width: 28.313rem;
    height: 38rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    background-color: #86BC25;
    box-shadow: 5px 5px 10px #00000029;
}

.chat-bot-option {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 33%;
    border: 1px solid gray;
    border-left: none;
    margin-bottom: 0.1rem;
    cursor: pointer;
}

.chat-bot-container .feedback-option {
    font-size: 0.6rem;
}

.chat-bot-activity-area {
    height: calc(100% - 9.375rem - 2px);
    width: 99%;
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    background-color: #F6F6F6;
    border-radius: 10px 10px 0px 0px;
    box-shadow: inset 0px 3px 6px #00000029;
}

.side-bar-icons {
    cursor: pointer;
    width: 90%;
    height: 3rem;
}

.maximize-view-icon {
    width: 100%;
    height: 100%;
}

.trainings-part {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 0rem 0.625rem 0.1rem 0.625rem;
    margin-top: 0.625rem;
    height: calc(100% - 1.2rem);
}

.chat-bot-stretched-area {
    height: calc(100% - 6.25rem - 2px);
    width: 99%;
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    background-color: #F6F6F6;
    padding: 0.5rem 0rem;
    box-sizing: border-box;
    border-radius: 10px;
}

.input-bottom-recom {
    display: flex;
    position: relative;
    background-color: white;
    border-radius: 0 0 10px 10px;
    width: 99%;
}

.reflective-ques-bottom {
    position: absolute;
    bottom: 100%;
    width: 100%;
}

.reflective-ques-tab {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    height: 2.125rem;
    width: max-content;
    font-weight: 600;
    padding: 0rem 0.5rem;
    font-size: 0.875rem;
    background-color: #B7E26B;
    border: 1px solid #86BC25;
    border-radius: 10px 10px 0 0;
    cursor: pointer;
}

.reflective-icon {
    height: 1.3125rem;
    width: 1.3125rem;
}

.reflective-open-icon img {
    height: 11px;
}

.reflective-ques-instructions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.625rem;
    /* height: 2.75rem; */
    background-color: white;
    border-top: 1px solid #86BC25;
    border-bottom: 1px solid #D5D5D5;
}

.reflective-ques-instructions-text {
    font-size: 0.75rem;
    padding-right: 0.625rem;
}

.reflective-button {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    font-weight: 600;
    padding: 0 1rem;
    background-color: #86BC25;
    border-radius: 10px;
    height: 1.75rem;
    cursor: pointer;
}

.session-modal {
    text-align: center;
    /* padding: 1.875rem; */
}

.session-modal-button {
    margin: 1.5625rem;
    border-radius: 2px;
    padding: 10px;
    cursor: pointer;
}

.session-modal-info {
    color: #999;
    font-style: italic;
}

/* @media (max-height: 720px) {
    .chat-bot-content-box {
        height: 35rem;
        transform: translateY(35px);
    }
} */

.ant-tour.antd-tour-modal {
    width: 23rem !important;
}

.antd-tour-modal .ant-tour-inner {
    width: 23rem !important;
}

.ant-tour-description {
    padding-top: 16px !important;
    width: 90% !important;
}

.tour-msg-img {
    transform: translateY(5px);
    width: 1.5rem;
    height: 1.5rem;
}

.tour-step-indicator {
    color: #595959;
}