.training-card-container {
    background-color: white;
    /* width: 22.5rem; */
    width: max-content;
    max-width: 82%;
    box-sizing: border-box;
    padding: 12px;
    box-shadow: 0px 3px 5px #0000001C;
    border: 1px solid #E2E2E2;
    border-radius: 6px;
    /* border-left: 7px solid #9d9d9d; */
}

.training-card-heading {
    font-size: 0.875rem;
    /* padding-bottom: 0.5rem; */
}

.unselected-training-card-container {
    border-left: 7px solid #D9D9D9;
}

.selected-training-card-container {
    transform: scale(1.01);
    border-left: 7px solid #86BC25;
}