.box {
  /* max-width: 22.3rem; */
  max-width: 82%;
  position: relative;
  display: flex;
  height: auto;
  flex-direction: column;
  margin-top: 2.5%;
  box-sizing: border-box;
}

.message_box {
  box-sizing: border-box;
  width: 100%;
  flex-shrink: 0;
  background: #ececec;
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3125rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0rem 12px;
  border: 1px solid #E2E2E2;
  border-radius: 6px;
  box-shadow: 0px 3px 5px #0000001C;
}

.message {
  max-width: 100%;
  /* text-align: justify; */
  word-wrap: break-word;
}

.message p {
  margin: 12px 0rem;
}

.message ul {
  padding-left: 1.15rem;
}

.sources-box {
  padding: 10px;
  padding-top: 18px;
  margin-left: 10px;
  padding-left: 0px;
  text-align: justify;
  width: 97%;
  border-top: 1px solid rgba(0, 124, 176, 0.6);
}

.sources-text {
  color: var(--black, #000);
  text-align: justify;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.25rem;
}

.links {
  margin-left: 1rem;
  margin-right: 1rem;
  color: #0068d0;
  text-align: justify;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
}

.message_user {
  position: absolute;
  width: 1.3rem;
  height: 1rem;
  flex-shrink: 0;
  border-radius: 0.26rem;
  border: 1px solid #aeaeae;
  align-self: flex-end;
  background: #ececec;
  margin-right: 40px;
  font-size: 0.75rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  padding: 2px;
  margin-top: 2.1%;
}

.copy_button_styling {
  position: absolute;
  top: 7.5px;
  right: 6px;
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  color: #ffffff;
  width: 3.8rem;
  border: 0.2px solid #989090;
  border-radius: 2px;
  background-color: #3d3b3b;
  height: 1.5rem;
  font-weight: 400;
}

.default_copy_button_styling {
  position: absolute;
  top: 7.5px;
  right: 6px;
  cursor: pointer;
  outline: none;
}

.default_icon_styling {
  font-size: 21px;
  opacity: 0.4;
}

.icon_styling {
  color: #ffffff;
}

.icon_styling:active::before {
  content: "copied!";
  font-size: 14;
  opacity: 0.8;
}

.icon_styling::before {
  content: "copy";
  font-size: 14;
  opacity: 0.8;
}

.link-pdf {
  cursor: pointer;
}

.source-link-color {
  display: inline;
  white-space: initial;
  word-break: break-all;
  color: #00A2FF;
  cursor: pointer;
  font-style: italic;
}

.source-link-header {
  width: 4rem;
  color: #55565B;
}

.source-link-container {
  display: flex;
  gap: 0.625rem;
  max-width: calc(100% - 4rem);
  flex-wrap: wrap;
}

.source-link-button {
  all: unset;
  border: 1px solid #E9E9E9;
  border-radius: 12px;
  color: black !important;
  height: 1.4375rem;
  font-size: 0.75rem;
  font-style: italic;
  cursor: pointer;
  width: max-content;
}

.source-link-button-text {
  padding: 0 0.75rem;
  max-width: 5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.source-link-tooltips {
  color: white;
  font-style: italic;
}