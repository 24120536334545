.training-right-section {
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.training-conv-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow-y: auto;
    margin-bottom: 0.625rem;
    box-sizing: border-box;
    gap: 0.8rem;
    margin-top: 0.625rem;
    padding: 0rem 0.625rem 2rem 0.625rem;
}

.filter-input-box {
    /* display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box; */
    position: relative;
    display: flex;
    font-family: "Open Sans", sans-serif;
    background-color: white;
    border-radius: 0 0 10px 10px;
    width: 100%;
    height: 3rem;
}

.chatbot-label-box {
    box-sizing: border-box;
    display: flex;
    font-size: 0.875rem;
    text-align: start;
    padding: 1rem;
    line-height: 1.3125rem;
    color: black;
    background-color: #FFF;
    border-radius: 7px;
    max-width: 85%;
}

.user-label-box {
    box-sizing: border-box;
    display: flex;
    font-size: 0.875rem;
    text-align: start;
    padding: 1rem;
    line-height: 1.3125rem;
    color: black;
    background-color: rgb(237, 245, 222);
    border-radius: 7px;
    max-width: 85%;
}

/* .aligning-message {
    display: flex;
    width: 100%;
} */