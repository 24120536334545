.self-ass-header {
  display: flex;
  margin-top: 1.5rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.sa-header-text-box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 80%;
}
.sa-header-heading {
  color: var(--Neutral-12, #141414);
  font-size: 1.25rem;
  font-style: normal;
  align-items: center;
  display: flex;
  gap: 0.75rem;
  font-weight: 600;
  line-height: 1.75rem;
}
.sa-header-content {
  color: var(--Neutral-8, #595959);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
}
.add-category-button {
  border-radius: 0.125rem;
  background: var(--Primary-green--, #92bb45);
  border: 1px solid var(--Primary-green--, #92bb45);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  padding: 0.25rem 0.9375rem;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  height: 2.5rem;
  color: var(--character-primary-inverse, #fff);
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 157.143% */
  cursor: pointer;
}
.self-ass-content-box {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 100%;
  margin: 0.75rem 0rem;
}
.collapse-style {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--Neutral-Neutral-0, #fff); /* Set background color */
  box-shadow: 0px 0.929px 1.857px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
}
.collapse-option-icons {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.self-ass-category-heading {
  color: #333840;
  font-size: 1.04475rem;
  font-style: normal;
  font-weight: 600;
  line-height: 143.55%; /* 1.49975rem */
  align-items: center;
  display: flex;
  gap: 0.75rem;
}
.box-9 {
  width: 100%;
  padding: 1rem 0.75rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.category-disclaimer-box {
  width: 100%;
  padding: 0rem 0.75rem 1rem 0.75rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  /* border-top: 1px solid rgba(0, 0, 0, 0.06);  */
  align-items: start;
}
.category-disclaimer-box-text {
  color: var(--Neutral-10, #262626);
  font-family: "Open Sans";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}
.resouce-link-color {
  color: var(--Hyper-link, #007cb0);
  font-family: "Open Sans";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 157.143% */
}
.divider-bottom {
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.06);
}
.sa-category-open {
  width: 100%;
  padding: 1rem 0.75rem;
  box-sizing: border-box;
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.resouce-link-box {
  display: flex;
  width: 100%;
  gap: 1rem;
  margin-bottom: 0.75rem;
  align-items: start;
  justify-content: space-between;
}
.resource-left-box {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5rem;
}
.category-link-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
}
.category-link-content-box {
  word-break: break-all;
  color: var(--Hyper-link, #007cb0);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  text-decoration-line: underline;
}
.self-view-question {
  display: flex;
  box-sizing: border-box;
  /* padding: 1rem 0.75rem; */
  justify-content: space-between;
  align-items: center;
}
.view-category-question-box {
  display: flex;
  flex-direction: column;
  /* width: 40%; */
  width: 50%;
  flex: 1;
  gap: 0.5rem;
}
.view-category-question-box-1 {
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: end;
  box-sizing: border-box;
  padding-left: 0.75rem;
}
.fw-600 {
  font-weight: 600 !important;
}
.view-options-box {
  width: 100%;
  display: flex;
}
.view-options-box-bg {
  background: #f6faf1;
  width: 100%;
  flex-direction: column;
  display: flex;
}
.options-box-self-ass {
  background: #f6faf1;
  width: 100%;
  display: flex;
  padding: 1rem 0.75rem;
  box-sizing: border-box;
  flex-direction: column;
}
.view-options-box-left {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1rem 0.75rem;
  width: 50%;
  box-sizing: border-box;
}
.view-options-box-right {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 50%;
  padding: 1rem 0.75rem;
  box-sizing: border-box;
}
.w-45 {
  width: 45%;
}
.mt-1 {
  margin-top: 1rem;
}
.self-asses-loader {
  display: flex;
  justify-content: center;
  height: calc(100% - 5rem);
  align-items: center;
}
.description-box-self-ass {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.mt-8 {
  margin-top: 0.5rem !important;
}
.ml-14 {
  margin-left: 0.75rem;
}
.textarea-view-description{
  display: flex;
  padding: 0.25rem 0.75rem 0rem 0.75rem;
  box-sizing: border-box;
}