.assesment-graph-box {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0rem;
  justify-content: space-between;
  box-sizing: border-box;
}
.p-l-0 {
  padding-left: 0rem !important;
}
