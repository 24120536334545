.input-bottom {
  width: 99%;
  height: 3rem;
  display: flex;
  border-radius: 0px 0px 10px 10px;
  background-color: white;
}

.input-bottom .input-field {
  width: calc(100% - 3rem);
  padding-left: 1rem;
  border: none;
  border-bottom-left-radius: 10px;
}

.input-bottom .input-field:focus {
  border: none;
  outline: none;
}

.input-bottom .chat-button {
  cursor: pointer;
  border-bottom-right-radius: 10px;
}

.cursor-n {
  cursor: not-allowed;
}

.submit-icon {
  height: 100%;
}

.submit-button-small-view {
  border: none;
  width: 3rem;
  display: flex;
  padding: 0.6rem;
  height: 3rem;
  justify-content: center;
  background-color: white;
  border-bottom-right-radius: 10px;
}

.ant-btn-default:disabled {
  background-color: white;
}
