.chat-bot-navbar{
    width: 100%;
}

.chat-bot-links-wrapper{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.chat-bot-nav-links{
    display: flex;
    align-items: center;
    gap: 0.5625rem;
    margin-right: 14px;
}

.chat-bot-links-right {
    display: flex;
    justify-content: space-between;
    width: 3rem;
    margin-left: 1rem;
}

.feedback-nav-button{
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    width: 2rem;
    background-color: #86BC25;
    box-shadow: 0px 3px 6px #00000036;
    border-radius: 4px;
    font-size: 0.75rem;
    cursor: pointer;
}

.feedback-nav-img {
    height: 100%;
    width: 1.25rem;
}

.feedback-nav-button img {
    height: 100%;
    width: 100%;
}

.expand-button{
    all: unset;
    cursor: pointer;
}

.expand-button img{
    width: 0.8rem;
    height: 0.8rem;
}

.minimize-button{
    all: unset;
    cursor: pointer;
}

.minimize-button img{
    width: 0.8rem;
    height: 0.8rem;
}

.chat-bot-tabs {
    display: flex;
    width: 100%;
    margin-top: 1.5rem;
}

.chat-bot-tabs-default {
    display: flex;
    /* gap: 0.3rem; */
    /* width: 90%; */
    align-items: flex-end;
    /* height: 3.5rem; */
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    padding-left: 0.7rem;
}

.hidden-tabs{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
}

.hamburger-icon {
    cursor: pointer;
}

.dropdown {
    width: 10rem;
    position: absolute;
    right: 0.1rem;
    top: 1.28rem;
    background-color: #86BC25;
    z-index: 1500;
    list-style: none;
    padding: 0;
}

.dropdown li {
    display: flex;
    justify-content: center;
    padding: 15px 0;
    cursor: pointer;
    border: 1px solid #AAE149;
}

.chat-bot-tab {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    cursor: pointer;
    /* width: 46%; */
    height: 2.25rem;
    padding: 0rem 1rem;
    text-align: center;
    font-size: 0.875rem;
    font-weight: bold;
}

.chat-bot-selected-tab {
    border-radius: 6px 6px 0px 0px;
    background-color: white;
    color: black;
}