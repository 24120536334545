.sidebar {
  position: absolute;
  width: 5rem;
  height: 100vh;
  background-color: #010101;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-top: 1rem;
  width: 100%;
}
.icon-selected {
  padding: 0.6rem 0rem;
  background-color: #92bb45;
  width: 100%;
  box-sizing: border-box;
  height: 3.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
}
.icon-not-selected {
  padding: 0.6rem 0rem;
  width: 100%;
  box-sizing: border-box;
  height: 3.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
}
.a:-webkit-any-link {
  color: white;
}
.link-item {
  color: white;
}
.deloitte-logo {
  padding-top: 0.75rem;
  box-sizing: border-box;
}
.pos-sidebar-icon{
  margin-top: 0.3rem;
}
.w-2-6{
  width:2.5rem;
}