.training-list-expanded {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.training-list-items-expanded {
    padding: 12px;
    font-size: 0.875rem;
    background-color: white;
    box-shadow: 0px 3px 5px #0000001C;
    border: 1px solid #E2E2E2;
    border-radius: 6px;
}

.training-item-link-expanded {
    font-size: 0.875rem;
    font-weight: 600;
    color: #00A2FF;
    text-decoration: underline !important;
}

.training-item-description{
    margin-top: 0.625rem;
    line-height: 21px;
}

.training-item-other-info-expanded {
    display: flex;
    gap: 0.625rem;
    font-size: 12px;
    margin-top: 0.5rem;
}

.training-items-vertical-separator-expanded {
    width: 1px;
    background-color: rgba(28, 37, 10, 0.4);
}