.feedback-container {
  width: 100%;
  height: 100%;
  /* overflow-y: auto; */
  padding: 0rem 0.5rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* gap: 0.65rem; */
}

.feedback-header {
  background-color: #2a2c30;
  border-radius: 6px;
  width: 100%;
  align-items: center;
  height: 2.3125rem;
  display: flex;
  box-shadow: 0px 3px 6px #000000b0;
  justify-content: space-between;
  /* padding: 2% 0% 2% 0%; */
  box-sizing: border-box;
}

.feedback-header-content {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.25rem;
  color: #f2f2f2;
}

.feedback-close-icon {
  color: #86bc25;
  font-weight: 600;
  font-size: 1.2rem;
  opacity: 1;
  cursor: pointer;
}

.feedback-ins-text {
  color: #53565a;
  width: 100%;
  font-size: 0.875rem;
  line-height: 21px;
  font-style: italic;
  /* padding-top: 0.65rem; */
}

.spinner-feedback {
  height: calc(100% - 2.3125rem);
  display: flex;
  align-items: center;
}

.submit-feedback-pos {
  display: flex;
  justify-content: end;
  width: 100%;
}

.submit-feedback-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: #86bc25;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  color: #000000;
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
}

.thanks-feedback-pos {
  display: flex;
  justify-content: start;
  width: 100%;
}

.thanks-feedback-box {
  background-color: #ffffff;
  border-radius: 7px;
  color: #010101;
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 1rem;
  padding: 0.5rem 1rem;
}

.close-icon-box {
  height: 100%;
  width: 2.875rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* padding: 0rem 0.5rem; */
  background-color: #000000b0;
}

.feedback-scroll-content {
  width: 100%;
  height: calc(100% - 2.3125rem);
  overflow-y: auto;
  gap: 0.625rem;
  flex-direction: column;
  display: flex;
  box-sizing: border-box;
  padding-bottom: 0.5rem;
  margin-top: 0.625rem;
}