.layout-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
}

.sub-page {
  width: 100%;
  height: calc(100vh - 4rem);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow-y: auto;
  padding: 0rem 2rem;
  box-sizing: border-box;
  padding-bottom: 2rem;
  background: #fcfcfc;
}
.admin-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding-left: 5rem;
  box-sizing: border-box;
}
.user-check-loader{
  position: absolute;
  top: 40%;
  left: 50%;
}