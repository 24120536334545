.date-range-picker .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
    background-color: rgba(146, 187, 69) !important;
}

.date-range-picker .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: rgba(146, 187, 69) !important;
}

.date-range-picker .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.date-range-picker .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.date-range-picker .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.date-range-picker .ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: rgba(146, 187, 69, 0.3) !important;
}

/* .date-range-picker .ant-picker-cell-in-range:hover {
    background-color: rgba(146, 187, 69, 0.3) !important;
} */

.date-range-picker .ant-picker-cell .ant-picker-cell-in-range::before:hover {
    background: rgba(146, 187, 69, 0.3) !important;
}