.empty {
  height: 100%;
  width: 100%;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content-empty {
  color: var(--neutral-8, #595959);
  text-align: center;
  width: 70%;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem; /* 140% */
}
.create-button-empty-page {
  padding: 0.4rem 0.9375rem;
  cursor: pointer;
  background-color: #92bb45;
  color: white;
  text-align: center;
  font-size: 1rem;
  line-height: 1.3125rem;
  border-radius: 0.125rem;
}
