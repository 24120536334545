.questions-gen-ai-container {
    margin-top: 0.625rem;
    padding: 0rem 0.625rem 0.1rem 0.625rem;
    height: calc(100% - 1.2rem);
    overflow-y: auto;
}

.question-instruction {
    box-sizing: border-box;
    padding: 12px;
    margin-bottom: 0.2rem;
    /* max-width: 22.3rem; */
    max-width: 82%;
    background-color: white;
    border: 1px solid #E2E2E2;
    border-radius: 6px;
    box-shadow: 0px 3px 5px #0000001C;
}

.generative-ai-questions {
    font-size: 0.875rem;
}

.default-question {
    width: max-content;
    max-width: 82%;
    box-sizing: border-box;
    color: #056B39;
    background-color: white;
    font-size: 0.875rem;
    border-radius: 20px;
    margin-top: 0.5rem;
    padding: 0.4rem 1rem;
    border: 1px solid #86BC25;
    font-weight: 500;
    cursor: pointer;
}

.default-question-selected {
    background-color: #D2E5B1;
}

.training-option-selected {
    background-color: aquamarine;
}

.chatbot-response {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.aligning-message {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    /* padding-right: 1rem; */
}

.default-questions-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: flex-end; */
}

.loading-chat {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}