.form-group-col-2 {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 95%;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
}
.form-group-col-3 {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
}
.add-button {
  border-radius: 0.125rem;
  cursor: pointer;
  border: 1px solid var(--Primary-green--, #92bb45);
  background: var(--neutral-1, #fff);

  /* drop-shadow/button-secondary */
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  display: flex;
  padding: 0rem 0.5rem;
  height: 1.5rem;
  justify-content: center;
  align-items: center;
  color: var(--Primary-green--, #92bb45);
  text-align: center;

  /* Body/regular */
  font-family: Open Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 157.143% */
}
.keywords-box {
  width: 100%;
  min-height: 6rem;
  border-radius: 0.375rem;
  box-sizing: border-box;
  padding: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  word-break: break-word;
  gap: 0.75rem;
  /* margin-top: 0.5rem; */
  border: 1px solid var(--neutral-6, #bfbfbf);
}
.button-box {
  display: flex;
  align-items: end;
  padding-bottom: 0.3rem;
}
.keyword-batch {
  display: inline-flex;
  align-items: center;
  gap: 0.3rem;
  height: max-content;
  padding: 0.2rem 0.5rem;
  box-sizing: border-box;
  border-radius: 1.25rem;
  background: rgba(146, 187, 69, 0.12);
  color: var(--Primary-green, #2e6a3e);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
  letter-spacing: 0.00625rem;
}
.cursor-p {
  cursor: pointer;
}
.content-options-1 {
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
  color: black;
  box-sizing: border-box;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  width: max-content;
  line-height: 1.375rem;
}
.upload-info-statement {
  border-radius: 0.125rem;
  background: rgba(187, 228, 198, 0.17);
  color: var(--primary-10, #001017);
  display: flex;
  align-items: center;
  gap: 0.4rem;
  padding: 0.625rem;
  width: 100%;
  box-sizing: border-box;
  margin-top: 1rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 157.143% */
}
.info-upload-icon {
  width: 1rem;
  height: 1rem;
  color: #2e6a3e !important;
}
.gap-5{
  gap: 0.75rem !important;
}