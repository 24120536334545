.responses-table-box {
  display: flex;
  /* flex-direction: column; */
  margin-top: 0.75rem;
  width: 100%;
  box-sizing: border-box;
}
.response-user-columm {
  display: flex;
  flex-direction: column;
  width: 30%;
  box-sizing: border-box;
}
.user-name-header {
  display: flex;
  /* width: 100%; */
  background-color: #f5f5f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.response-table-content {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
}
.header-options-1 {
  color: black;
  padding: 0.8rem 0.75rem;
  box-sizing: border-box;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
}
.response-details-columm {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  overflow-x: auto;
}
.user-feedback-row {
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.user-feedback-header{
  display: flex;
  width: 100%;
  background-color: #f5f5f5;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.response-content-options {
  /* display: flex;
  gap: 0.75rem; */
  color: black;
  padding: 0.6rem 0.75rem;
  box-sizing: border-box;
  font-size: 0.875rem;
  word-wrap: break-word;
  /* overflow-x: hidden; */
  /* -webkit-line-clamp: 3; */
  /* text-overflow: ellipsis; */
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
}
.response-content-options-not-exp {
  color: black;
  padding: 0.6rem 0.75rem;
  box-sizing: border-box;
  font-size: 0.875rem;
  word-wrap: break-word;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  line-height: 1.375rem;
}
.width-30 {
  width: 30%;
}
.width-90 {
  width: 85%;
}
.width-25 {
  width: 25%;
}
.width-20 {
  width: 20%;
}
.width-15 {
  width: 16%;
}
.width-10{
  width: 10%;
}
.width-33 {
  min-width: 33.33%;
}

.response-details-columm::-webkit-scrollbar {
  height: 0.2rem;
}

.response-details-columm::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 1.25rem;
}

.response-details-columm::-webkit-scrollbar-track {
  background-color: transparent;
  /* Make the track transparent */
}
.header-options-1::-webkit-scrollbar {
  height: 0.2rem;
}

.header-options-1::-webkit-scrollbar-thumb {
  background-color: transparent;
  /* border-radius: 1.25rem; */
}

.header-options-1::-webkit-scrollbar-track {
  background-color: transparent;
  /* Make the track transparent */
}
.link-color {
  color: #007eb3 !important;
}
.modal-item {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-right: 0.5rem;
}
.h-max {
  height: max-content;
}
.title-modal-css {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
}
.text-modal-css {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  margin: 0.75rem 0rem;
  padding: 0.5rem 0rem;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  line-height: 1.375rem;
}
