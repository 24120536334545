.configuration-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 0.5rem;
  gap: 0.5rem;
}
.filter-main-box {
  width: 100%;
  display: flex;
  gap: 2rem;
  padding-top: 0.75rem;
  box-sizing: border-box;
}
.filters-left-box {
  border-radius: 0.375rem;
  background: var(--neutral-1, #fff);
  width: 13rem;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1.5rem;
  min-height: 20rem;
  /* max-height: 35rem; */
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  height: max-content;
}
.filters-right-box {
  border-radius: 0.375rem;
  background: var(--neutral-1, #fff);
  width: 60%;
  /* drop-shadow/0.15 */
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
  box-sizing: border-box;
  min-height: 21.625rem;
  /* max-height: 35rem; */
  height: max-content;
  overflow-y: auto;
}
.active-filter-heading {
  color: var(--neutral-13, #000);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}
.filters-list-box-config {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
}

.filter-box-selected-config {
  background: rgba(146, 187, 69, 0.06);
  box-shadow: -2px 0px 0px 0px #92bb45 inset;
  color: var(--Primary-green--, #92bb45) !important;
}
.right-header {
  display: flex;
  justify-content: space-between;
}
.header-heading {
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
}
.filter-option-box-config {
  display: flex;
  gap: 0.5rem;
}
.add-filter-option-config {
  border-radius: 0.125rem;
  background: var(--Primary-green--, #92bb45);
  cursor: pointer;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  color: var(--character-primary-inverse, #fff);
  text-align: center;
  border: 1px solid #92bb45;
  padding: 0.25rem 0.9375rem;
  font-size: 0.875rem;
  align-items: center;
  display: flex;
  height: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
}
.filter-input-config {
  border-radius: 0.125rem;
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-1, #fff);
  width: 15rem;
  padding-left: 0.5rem;
}
.filters-category-content-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.25rem;
}
.filters-content-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
  box-sizing: border-box;
}
.category-header {
  display: flex;
  justify-content: space-between;
}
.delete-icon {
  color: #2e6a3e;
}
.recently-added-content {
  color: #000;
  /* H5/medium */
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
  width: max-content;
  display: flex;
  align-items: center;
}
.checkbox-text {
  color: var(--neutral-7, #8c8c8c);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  text-overflow: ellipsis;
}
.add-filter-box-config {
  border-radius: 0.125rem;
  background: #f7fff9;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  color: var(--Primary-green, #2e6a3e);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 157.143% */
}
.add-filter-input {
  width: 100%;
}
.add-filter-input-config {
  color: black;
  border: none;
  max-width: 7rem;
}
.add-filter-input-config:focus-visible {
  border: none;
  outline: none;
}
.filter-input-config:focus-visible {
  border: none;
  outline: 1px solid gray;
}
.filter-box-config {
  padding: 0.5rem 1.5rem;
  display: flex;
  width: 100%;
  color: black;
  box-sizing: border-box;
  justify-content: space-between;
  /* border-right: 2px solid #2E6A3E; */
  position: relative;
  cursor: pointer;
  width: 100%;

  /* Body/medium */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem; /* 157.143% */
}
.dropdown-filter {
  width: 4rem;
  /* left: 8rem; */
  right: -1rem;
  top: 2rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  position: absolute;
  border-radius: 0.125rem;
  z-index: 999;
  background: var(--conditional-pop-over, #fff);
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
}
.dropdown-content {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  height: 1.25rem;
  cursor: pointer;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  padding: 0.05rem 0.1rem;
  line-height: 1.375rem; /* 157.143% */
}
.config-main-loader {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 80%;
  justify-content: center;
}
.cursor-p {
  cursor: pointer;
}
.filter-input-box-config {
  border-radius: 0.125rem;
  border: 1px solid var(--neutral-5, #d9d9d9);
  /* background: #f7fff9; */
  display: flex;
  justify-content: space-between;
  font-family: "Open Sans", sans-serif;
  padding: 0.5rem;
  gap: 0.25rem;
  width: 100%;
  box-sizing: border-box;
}
.checkbox-section {
  display: flex;
  padding-bottom: 0.25rem;
  align-items: center;
  width: 45%;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.06);
  justify-content: space-between;
}
.small-delete {
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
  cursor: pointer;
}
.dash-color {
  color: #2e6a3e;
}
.hover-configuration {
  background-color: #f5f5f5;
}
