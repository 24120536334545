.filter-option {
    position: relative;
}

.filter-option-header {
    border: 1px solid rgba(217, 217, 217, 1);
    background-color: #ffffff;
    height: 1.925rem;
    box-sizing: border-box;
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    cursor: pointer;
    gap: 2rem;
    min-width: 10.625rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
}

.filter-all-option-box-selected {
    background-color: #88b79463;
}

.filter-option-header-selected {
    font-weight: bold;
    color: #056B39;
}

.filter-right-arrow {
    display: flex;
    align-items: center;
}

.filter-options-2 {
    position: absolute;
    z-index: 1400;
    left: calc(100% + 5px);
    bottom: 0.1rem;
    display: flex;
    flex-direction: column;
    width: max-content;
    max-height: 23.4rem;
    overflow-y: auto;
    background-color: white;
    box-shadow: 0px 5px 6px #00000029;
    border-radius: 2px;
    padding: 0.1rem 0.5rem;
    font-size: 0.75rem;
}

.filter-options-2::-webkit-scrollbar {
    width: 5px;
    /* Set the width as needed */
}

.filter-options-2::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 7px;
    /* border-radius: 10px; Make the thumb transparent */
}

.filter-options-2::-webkit-scrollbar-track {
    background-color: transparent;
    /* Make the track transparent */
}

.filter-option-text {
    position: relative;
}

.individual-option-no {
    position: absolute;
    color: white;
    text-align: center;
    background-color: rgba(147, 187, 68, 1);
    top: -2px;
    left: calc(100% + 5px);
    font-weight: 600;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.2rem;
    height: 1.2rem;
}

@media (max-width: 1250px) {
    .filter-options-2 {
        max-width: 11rem;
    }
}