.navbar {
  background-color: #f6faf1;
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  fill: #f6faf1;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.14));
  padding-left: 1.3rem;
  box-sizing: border-box;
  gap: 0.875rem;
}
.super-tutor-logo {
  color: #2e6a3e;
  /* H3/medium */
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
  box-sizing: border-box;
}
