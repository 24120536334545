.heading-tab-dashboard {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding-top: 0.2rem;
}
.calender-section {
  width: max-content;
  height: 2.5rem !important;
  gap: 0.75rem;
  display: flex;
  align-items: center;
}
.fiscal-year-selection {
  width: max-content;
  height: 100%;
}
.fiscal-year-select {
  height: 2rem;
}
.custom-select {
  min-height: 2rem;
}

.custom-value-container {
  height: 2rem;
  padding: 0.25rem;
}

.custom-input {
  margin: 0;
}
.usage-metrics-card {
  width: 100%;
  margin: 0.75rem 0rem;
  /* min-height: 24rem; */
  border-radius: 0.1875rem;
  background: var(--neutral-1, #fff);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  /* padding-bottom: 0.5rem; */
  box-sizing: border-box;
}
.assesment-strategy-card {
  width: 100%;
  /* margin: 0.75rem 0rem; */
  height: 32.5%;
  border-radius: 0.1875rem;
  background: var(--neutral-1, #fff);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  /* padding-bottom: 0.5rem; */
  box-sizing: border-box;
}
.bottom-dashboard-section {
  display: flex;
  gap: 2.25rem;
  width: 100%;
  height: 70rem;
  margin-bottom: 2rem;
  justify-content: space-between;
}
.use-case-filter {
  width: 64%;
  height: 70rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
.trending-learning-card {
  height: max-content;
  max-height: 70rem;
  min-height: 21.6rem;
  width: 34%;
  border-radius: 0.1875rem;
  background: rgba(164, 185, 170, 0.05);
  background: var(--neutral-1, #fff);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  /* padding: 0.75rem; */
  box-sizing: border-box;
}
.use-case-card {
  background: var(--neutral-1, #fff);
  border-radius: 0.1875rem;
  /* drop-shadow/0.15 */
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  height: 32.5%;
  padding-bottom: 0.75rem;
  box-sizing: border-box;
}
.filter-card {
  background: var(--neutral-1, #fff);
  border-radius: 0.1875rem;
  /* drop-shadow/0.15 */
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  height: 32.5%;
  box-sizing: border-box;
}
.dashboard-loader {
  position: absolute;
  top: 40%;
  left: 50%;
}
.metric-usage-header {
  display: flex;
  justify-content: space-between;
  border-radius: 0.1875rem 0.1875rem 0rem 0rem;
  background: rgba(164, 185, 170, 0.05);
  padding: 0.75rem 1.25rem;
  box-sizing: border-box;
  height: 4rem;
  align-items: center;
}
.metric-usage-heading {
  color: var(--neutral-12, #141414);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
}
.view-button {
  border-radius: 0.125rem;
  border: 1px solid var(--Primary-green--, #92bb45);
  background: var(--Neutral-1, #fff);

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0rem 0.4rem;
  margin-right: 0.5rem;
  cursor: pointer;
  /* drop-shadow/button-secondary */
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  color: var(--Primary-green--, #92bb45);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 157.143% */
}
.usage-metric-all-graph-box {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
}
.assessment-graph-box {
  display: flex;
  width: 100%;
  padding: 0.5rem 1.25rem;
  box-sizing: border-box;
  height: calc(100% - 4rem);
}
.flex-graph {
  display: flex;
  flex: 1;
  height: 20rem;
  /* width: 49%; */
  box-sizing: border-box;
  /* gap: 1.5rem; */
  min-width: 30rem;
  /* min-width: 45rem; */
}
.right-section-header {
  display: flex;
  gap: 2rem;
  align-items: center;
}
.empty-data-img-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
  padding-bottom: 1rem;
  box-sizing: border-box;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.empty-usage-data-img-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  width: 100%;
  height: calc(100% - 3rem);
}
.empty-text {
  color: var(--Neutral-8, #595959);
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 157.143% */
}
.usage-metrics-divider {
  stroke-width: 1px;
  stroke: var(--Conditional-divider, rgba(0, 0, 0, 0.06));
  background-color: rgba(0, 0, 0, 0.06);
  /* width: 100%; */
  /* margin: 0rem 1.5rem; */
  margin: 0rem 0.75rem;
  border-radius: 0.25rem;
  height: 18rem;
  width: 0.1rem;
  /* margin-top: 5%; */
}
/* td.ant-picker-cell-range-end.ant-picker-cell-selected .ant-picker-cell-inner { */
td.ant-picker-cell-range-end.ant-picker-cell-selected .ant-picker-cell-inner {
  background: rgba(146, 187, 69, 0.3) !important;
  color: black !important;
  font-weight: 600 !important;
  font-size: 0.9rem !important;
}
.ant-picker-cell-selected::before {
  background-color: rgba(146, 187, 69, 0.3) !important;
}
/* .ant-picker-cell-selected:hover {
  background-color: rgba(146, 187, 69, 0.3)!important;
} */
.ant-picker-cell-range-end::before {
  background-color: rgba(146, 187, 69, 0.3) !important;
}
.ant-picker-cell-in-range::before {
  background-color: rgba(146, 187, 69, 0.3) !important;
}
.ant-picker-cell-in-range:hover .ant-picker-cell-inner {
  background-color: rgba(146, 187, 69, 0.3) !important;
}
.ant-picker-cell-inner::before {
  outline: none !important;
  border: none !important;
}
.ant-picker-cell-inner:hover {
  background-color: rgba(146, 187, 69, 0.3) !important;
}
.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: rgba(146, 187, 69, 0.3) !important;
  background-color: rgba(146, 187, 69, 0.3) !important;
  color: black !important;
  font-weight: 600 !important;
}
td.ant-picker-cell-range-start.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: rgba(146, 187, 69, 0.3) !important;
  color: black !important;
  font-weight: 600 !important;
  font-size: 0.9rem !important;
}
.aligned-li {
  margin-left: -1.5rem;
}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled) .ant-picker-cell-inner{
  color: black !important;
}
