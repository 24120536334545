.show-boxes-each {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  margin-top: 0.75rem;
}
.box-details-1 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}
.box-details-input-save {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  /* height: 3rem; */
  gap: 2rem;
}   
.width-80 {
  width: 80%;
}
.whit-wrap {
  white-space: nowrap;
}
.mh-2{
  min-height: 2rem !important;
}
.dummy-textarea {
  border-radius: 0.125rem;
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-1, #fff);
  display: flex;
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  min-height: 7rem;
  /* height: 5rem; */
  resize: none;
  /* overflow-y: hidden; */
  overflow-y: scroll;
  box-sizing: border-box;
}
.dummy-textarea:focus {
  outline: none !important;
}
.dummy-textarea:disabled {
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-4, #f0f0f0);
  cursor: default;
  color: black;
}