.training-section-box {
    display: flex;
    flex-direction: column;
    width: 66.6%;
    height: calc(100% - 1rem);
    gap: 1rem;
    margin-top: 0.625rem;
    padding: 0 0.625rem 0 0.625rem;
    box-sizing: border-box;
    overflow-y: auto;

}

.training-section-box::-webkit-scrollbar {
    height: 0.2rem;
    width: 0.5rem;
}

.training-section-box::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 1.25rem;
}

.training-section-box::-webkit-scrollbar-track {
    background-color: transparent;
}

.trainging-heading {
    font-size: 0.75rem;
    font-weight: bold;
    color: #000000;
    padding-top: 1%;
    padding-left: 1%;

}

.trainings-container-expanded {
    /* padding-bottom: 0.5rem; */
    /* padding-right: 0.2rem; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.75rem;
}

.request-box {
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    color: black;
    font-weight: 600;
}

.training-right-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10rem;
}

.horizontal-sep {
    height: 1px;
    margin: 1%;
    background-color: #00000029;
    border: 0.01px solid #00000029;
    border-radius: 2px;
}

.spinner {
    margin-top: 25%;
}

.request-box {
    font-size: 0.875rem;
    color: black;
    padding-left: 1%;
}

.training-section-instructions{
    font-size: 16px;
    color: #595959;
    font-style: italic;
}

@media (max-width: 1250px) {
    .training-section-box {
        width: 63%;
    }
}