.heading-tab-feedback {
  width: max-content;
  color: #2e6a3e;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  color: Gray;
}
.admin-feedback-nav-heading {
  color: var(--neutral-12, #141414);
  text-align: start;
  font-size: 1.25rem;
  font-style: normal;
  margin-top: 0.5rem;
  font-weight: 600;
  line-height: 1.75rem; /* 140% */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.edit-question-button {
  border-radius: 0.125rem;
  background: var(--Primary-green--, #92bb45);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  /* height: 2.15rem; */
  padding: 0.5rem 0.9375rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  color: var(--character-primary-inverse, #fff);
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 157.143% */
  cursor: pointer;
}
.button-faded {
  color: var(
    --character-disabled-placeholder-25,
    rgba(0, 0, 0, 0.25)
  ) !important;
  border: 1px solid var(--Neutral-5, #d9d9d9) !important;
  background: var(--Neutral-3, #f5f5f5) !important;
  cursor: not-allowed !important;
}
