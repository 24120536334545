.textarea-content-disclaimer {
  width: 100%;
  min-height: 5rem;
}
.disabled-color {
  color: #8c8c8c !important;
}
.ql-container.ql-snow {
  height: 6rem;
}
.disclaimer-add-links-box {
  display: flex;
  flex-direction: column;
  padding: 1rem 0.75rem;
  box-sizing: border-box;
  gap: 0.75rem;
}
.ql-snow .ql-tooltip {
  margin-left: 7rem;
}
.text-area-desc-content-self-ass {
  color: #272b30;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.3125rem; /* 150% */
}
