.heading-tab {
  width: max-content;
  color: #2e6a3e;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  color: Gray;
}

.heading-tab-pad {
  margin-top: 1.5rem;
  /* padding: 0.2rem 0rem; */
}
.sub-heading {
  color: #667085;
  cursor: pointer;
}
.subbed-heading {
  color: #2e6a3e;
}
.content-heading-main {
  color: black;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem; /* 140% */
}
.navigation-tab {
  display: flex;
  width: max-content;
  /* gap: 1rem; */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  margin-top: 0.75rem;
  cursor: pointer;
}
.data-tab {
  padding: 0rem 1rem 0.5rem 0rem;
  border-bottom: 2px solid rgba(0, 0, 0, 0.06);
}
.data-selected-tab {
  padding: 0rem 1rem 0.5rem 0rem;

  color: #92bb45;
  border-bottom: 2px solid #92bb45;
}
.config-tab {
  padding: 0rem 0rem 0.5rem 1rem;
  border-bottom: 2px solid rgba(0, 0, 0, 0.06);
}
.config-selected-tab {
  padding: 0rem 0rem 0.5rem 1rem;
  color: #92bb45;
  border-bottom: 2px solid #92bb45;
}
.delete-modal {
  display: flex;
  justify-content: end;
  width: 100%;
  gap: 1rem;
}
.title-content {
}
.delete-title-box {
  display: flex;
  gap: 1rem;
  width: 90%;
}
.no-box {
  border-radius: 2px;
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--conditional-pop-over, #fff);

  /* drop-shadow/button-secondary */
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  display: flex;
  height: 2.25rem;
  padding: 0rem 0.75rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
}
.yes-box {
  display: flex;
  padding: 0rem 0.75rem;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  color: white;
  height: 2.25rem;
  border-radius: 0.125rem;
  background: var(--Primary-green--, #92bb45);
  cursor: pointer;
  /* drop-shadow/button-primary */
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
}
