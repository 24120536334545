.input-bottom-expanded {
    width: 100%;
    /* margin: auto; */
    height: 3rem;
    display: flex;
    border-radius: 0px 0px 10px 10px;
    background-color: white;
}

.input-bottom-expanded .input-field {
    width: calc(100% - 3rem);
    padding-left: 1rem;
    border: none;
    border-radius: 10px;
}

.input-bottom-expanded .input-field:focus {
    border: none;
    outline: none;
}

.input-bottom-expanded .chat-button {
    cursor: pointer;
    border-bottom-right-radius: 10px;
}

.cursor-n {
    cursor: not-allowed;
}

.submit-icon {
    height: 100%;
}

.submit-button-expanded-view {
    border: none;
    width: 3rem;
    display: flex;
    padding: 0.6rem;
    height: 3rem;
    justify-content: center;
    background-color: white;
    border-bottom-right-radius: 10px;
}

.ant-btn-default:disabled {
    background-color: white;
}